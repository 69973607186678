import { createRoot } from 'react-dom/client';
import { Provider } from 'urql';

import { createGQLClient } from '@/gql/gqlClient';

import { initializeAuthData, refreshTokens } from '@/store/auth/auth';

import App from '@/App';

import './i18n';

import './index.css';
import '@com.siemens.plm.horizon/swwc-core-styles/css/__obsolete/swwc-icons--all.css';

const container = document.getElementById('root');
const root = createRoot(container);

async function renderApp() {
   initializeAuthData(localStorage, refreshTokens);
   const client = await createGQLClient();

   root.render(
      <Provider value={client}>
         <App />
      </Provider>,
   );
}

renderApp();
