// @ts-nocheck
import { compose, equals, groupBy, head, ifElse, isEmpty } from 'ramda';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useClient } from 'urql';

import { featureFlagsStore } from '@/store/featureFlags/state';
import { store } from '@/store/listing/state';
import { actionsModalStore } from '@/store/modal/store';
import { useStream } from '@/store/useStream';

import { onMonitor } from '@/modules/common/buildJobActionsClickHandler';

import { isRunning } from '@/utils/comparators';
import { jobTypeNames } from '@/utils/constants';

import { SwwcButton, SwwcModalDialog } from '@/swwc';

const byJobType = groupBy((job) => (job.jobTypeName === jobTypeNames.hpc ? 'hpc' : 'remote'));
const isJobRunning = compose(isRunning, head);

const confirmActions = (selection, isMultiVersionEnabled, client, navigate) =>
   ifElse(
      equals('delete'),
      () => {
         store.actions.deleteJobs(client, selection);
         navigate('/');
      },
      () =>
         isMultiVersionEnabled
            ? store.actions.terminateJob(client, selection[0])
            : store.actions.terminateComputeJob(client, selection[0]),
   );

const Modal = ({ type, action, selection, onConfirm, onLaunchMonitor }) => {
   const { t } = useTranslation();
   const commandsAction = t(`commands.${action}`);
   const subjectType = t(`modal.subject.${type}`, { count: selection.length });
   const title = `${commandsAction} ${subjectType}`;

   return (
      <SwwcModalDialog
         opened
         isModal
         onOpenedChange={actionsModalStore.actions.close}
         className="absolute"
         type="input"
         dialogTitle={title}
      >
         <div className="max-w-2xl">{getModalContent(action, type, selection)}</div>
         <SwwcButton slot="actions" label="Cancel" variant="base" onClickEvent={actionsModalStore.actions.close} />
         <SwwcButton slot="actions" label={title} variant="accent-negative" onClickEvent={onConfirm} />
         {type === 'hpc' && action === 'terminate' && isJobRunning(selection) ? (
            <SwwcButton
               slot="actions"
               label={t('modal.terminate.hpc.monitor.button')}
               icon="cmdLaunchSimulationTool24"
               variant="accent-high-contrast"
               onClickEvent={onLaunchMonitor}
            />
         ) : null}
      </SwwcModalDialog>
   );
};

function getModalContent(action, type, selection) {
   if (action === 'delete') {
      return type === 'mixed' ? getMixedTypeDeleteBody(selection) : getSingleTypeDeleteBody(type, selection);
   }

   return getTerminateBody(type, selection);
}

const modalConfirmStr = 'modal.confirm';
function getTerminateBody(type, selection) {
   const { t } = useTranslation();

   return (
      <>
         <p>{t(`modal.terminate.${type}.body`)}</p>
         <p>{type === 'hpc' && isJobRunning(selection) ? t(`modal.terminate.${type}.monitor.message`) : null}</p>
         <ul className="pl-4 my-4 ml-4 list-disc">
            {type === 'remote'
               ? t('modal.terminate.remote.list', { returnObjects: true }).map((item) => <li key={item}>{item}</li>) // the translation returns an array of strings
               : null}
         </ul>
         <p>{t(modalConfirmStr)}</p>
      </>
   );
}

function getSingleTypeDeleteBody(type, selection) {
   const { t } = useTranslation();
   const list = t(`modal.delete.${type}.list`, { returnObjects: true });

   return (
      <>
         {selection.length > 1 ? (
            <>
               <p>{t(`modal.delete.${type}.multiple`)}</p>
               <ul className="pl-4 my-4 ml-4 list-disc">
                  {selection.map((session) => (
                     <li key={session.id}>{session.name}</li>
                  ))}
               </ul>
            </>
         ) : null}
         <p>{t(`modal.delete.${type}.body`, { count: selection.length })}</p>
         <ul className="pl-4 my-4 ml-4 list-disc">
            {list.map((item) => (
               <li key={item}>{item}</li>
            ))}
         </ul>
         <p>{t(modalConfirmStr)}</p>
      </>
   );
}

function getMixedTypeDeleteBody(selection) {
   const { t } = useTranslation();
   const hpcList = t('modal.delete.hpc.list', { returnObjects: true });
   const remoteList = t('modal.delete.remote.list', { returnObjects: true });

   const jobs = byJobType(selection);

   return (
      <>
         <p>{t('modal.delete.hpc.multiple')}</p>
         <ul className="pl-4 my-4 ml-4 list-disc">
            {jobs.hpc.map(({ id, name }) => (
               <li key={id}>{name}</li>
            ))}
         </ul>
         <p>{t('modal.delete.hpc.body', { count: 2 })}</p>
         <ul className="pl-4 my-4 ml-4 list-disc">
            {hpcList.map((item) => (
               <li key={item}>{item}</li>
            ))}
         </ul>
         <hr className="my-4" />
         <p>{t('modal.delete.remote.multiple')}</p>
         <ul className="pl-4 my-4 ml-4 list-disc">
            {jobs.remote.map(({ id, name }) => (
               <li key={id}>{name}</li>
            ))}
         </ul>
         <p>{t('modal.delete.remote.body', { count: 2 })}</p>
         <ul className="pl-4 my-4 ml-4 list-disc">
            {remoteList.map((item) => (
               <li key={item}>{item}</li>
            ))}
         </ul>
         <p>{t(modalConfirmStr)}</p>
      </>
   );
}

export const ActionsJobModal = () => {
   const client = useClient();
   const navigate = useNavigate();
   const { action, selection, type } = useStream(actionsModalStore.state);
   const { isMultiVersionEnabled } = useStream(featureFlagsStore.state);

   const handleConfirm = () => {
      confirmActions(selection, isMultiVersionEnabled, client, navigate)(action);
      actionsModalStore.actions.close();
   };

   const handleMonitor = () => {
      onMonitor(selection[0]);
      actionsModalStore.actions.close();
   };

   if (isEmpty(type)) {
      return null;
   }

   return createPortal(
      <Modal
         type={type}
         action={action}
         selection={selection}
         onConfirm={handleConfirm}
         onLaunchMonitor={handleMonitor}
      />,
      document.body,
   );
};
