import { BrowserRouter } from 'react-router-dom';

import { ModuleRouter } from '@/modules/Routes';

import { addPrecedingSlash, formatBaseUrl } from './utils/formatters';

function App() {
   // TODO - when logging in the hpcJob part of the URL would be lost. We should
   // eventually fix this
   if (window.location.pathname.includes('jobmanager/newjob')) {
      const uri = `${import.meta.env.VITE_DOMAIN}${formatBaseUrl(import.meta.env.BASE_URL)}hpcJob`;
      console.warn(`Redirecting from ${window.location.href} to ${uri}`);
      window.location.href = uri;
      return null;
   } else if (window.location.origin.includes('poweroncloud')) {
      const uri = `${import.meta.env.VITE_DOMAIN}${addPrecedingSlash(import.meta.env.BASE_URL)}`;
      console.warn('Redirecting from poweroncloud to ', uri);
      window.location.href = uri;
      return null;
   }
   return (
      <BrowserRouter basename={import.meta.env.BASE_URL}>
         <ModuleRouter />
      </BrowserRouter>
   );
}

export default App;
