import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { featureFlagsStore } from '@/store/featureFlags/state';
import { store } from '@/store/listing/state';
import { store as settingsStore } from '@/store/settings/store';
import { useStream } from '@/store/useStream';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

import { dataStates } from '@/utils/constants';

import { SwwcInformationBar } from '@/swwc';

import { AppsList } from './apps/AppsList';
import { JobManager } from './jobManager/JobManager';

export const Listing = () => {
   const client = useClient();
   const featureFlags = useStream(featureFlagsStore.state);
   const { workspace, totalExistingJobs, dataState } = useStream(store.state);
   const { fullWidthTable } = useStream(settingsStore.state);
   const { t } = useTranslation();

   useEffect(() => {
      if (featureFlags.dataState === dataStates.ready) {
         if (featureFlags.isMultiVersionEnabled) {
            store.actions.loadJobs(client);
         } else {
            store.actions.loadComputeJobs(client);
         }
      }
   }, []);

   if (dataState === dataStates.error && totalExistingJobs === 0) {
      return (
         <div className="grid place-items-center h-full">
            <SwwcInformationBar informationText={t('errors.jobGetError')} variant="error" useIcon={true} />
         </div>
      );
   }

   // Only showing the loading spinner before the initial workspace fetchs
   if (!workspace.id) {
      return <LoadingIndicator />;
   }

   return totalExistingJobs === 0 ? (
      <AppsList />
   ) : (
      <div className={`flex flex-col mt-2 gap-5 ${fullWidthTable ? 'mx-2' : 'max-w-screen-xl m-auto'}`}>
         <JobManager />
      </div>
   );
};
