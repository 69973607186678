// @ts-nocheck
import {
   all,
   anyPass,
   chain,
   compose,
   cond,
   curry,
   equals,
   gte,
   has,
   includes,
   isEmpty,
   isNil,
   isNotNil,
   lastIndexOf,
   mergeDeepLeft,
   none,
   prop,
   propEq,
   replace,
   slice,
   test,
   values,
} from 'ramda';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { fileExtensions, forbiddenContent } from '@/utils/constants';
import { nameConstraints } from '@/utils/errors';

const removeExtension = chain(slice(0), lastIndexOf('.'));
const isNotExtension = curry((ext, value) => !value.name.endsWith(fileExtensions[ext]));

const isEmptySize = compose(gte(0), prop('size'));

const isNameForbidden = test(forbiddenContent);
const isFilenameForbidden = compose(isNameForbidden, removeExtension, prop('name'));

const simSyncConstraints = [isNil, isNotExtension('simulationFile'), isEmptySize, isFilenameForbidden];
export const areSyncConstraintsInvalid = anyPass(simSyncConstraints);

const macroConstraints = [isNotExtension('macro'), isEmptySize, isFilenameForbidden];

const isNameInvalid = ({ value }) => anyPass(nameConstraints)(value);
const isMacroInvalid = ({ value }) => (isNil(value) ? false : anyPass(macroConstraints)(value));
const isTemplateInvalid = ({ value }) => isNil(value);
const isOtherFilesInvalid = ({ value }) => value.some(isFilenameForbidden);

export const validateField = cond([
   [propEq('name', 'key'), isNameInvalid],
   [propEq('macro', 'key'), isMacroInvalid],
   [propEq('otherFiles', 'key'), isOtherFilesInvalid],
   [propEq('submissionTemplate', 'key'), isTemplateInvalid],
]);

export const trimExtension = compose(replace(/.sim$/, ''), prop('name'));
const hasNoError = compose(all(equals(false)), values, prop('errors'));

/**
 * Enable/Disable the submit button of the job submission form
 * On mount there are no errors since field validation is made on field update
 * So we check if required fields are filled or not as well here
 */
export function allowSubmission(state) {
   return none(isNil, [state.name, state.simulationFile, state.submissionTemplate]) && hasNoError(state);
}

export const updateJobNameRequired = (current, proposed) => {
   return (
      current.simulationFile !== proposed.simulationFile &&
      isNotNil(proposed.simulationFile) &&
      equals(false, proposed.errors.simulationFile) &&
      isEmpty(current.name)
   );
};

export const updatePrecisionRequired = (proposed) =>
   isNotNil(proposed.version.selected) && isNotNil(proposed.precision.selected);
const appHandlesPrecision = (app, precision) => has('precision', app) && !includes(precision, app.precision);
export const handlePrecisionUpdate = (applications, proposed) => {
   const apps = applications?.cloud_hpc ?? [];
   const app = getTemplateByMarketingVersion(proposed.version.selected)(apps);

   // TODO: non multiversion apps do not have precision information, update that check in the future
   if (appHandlesPrecision(app, proposed.precision.selected)) {
      return mergeDeepLeft({ precision: { selected: app.precision[0] } }, proposed);
   }

   return null;
};
