import { always, any, both, compose, cond, ifElse, pathEq, propEq, T, unless } from 'ramda';

import { isFinished, isInProcess, isPreparing, isRunning, isStatusDeleting } from '@/utils/comparators';
import { jobTypeKeys, jobTypeNames, quickActions } from '@/utils/constants';
import { createUrl } from '@/utils/formatters';

export const goToDetails = (navigate) => unless(isStatusDeleting, compose(navigate, createUrl));

const connectOrMonitor = ifElse(
   propEq(jobTypeNames.remote, 'jobTypeName'),
   always(quickActions.connect),
   always(quickActions.monitor),
);

export const getActions = cond([
   [isRunning, (job) => [quickActions.terminate, connectOrMonitor(job)]],
   [isFinished, always([quickActions.delete])],
   [T, always([quickActions.terminate])],
]);

// We want to display the connect button for a remote desktop while the session is queued
// specifically on the detail page
export const getRemoteDetailsActions = cond([
   [isFinished, always([quickActions.delete])],
   [T, always([quickActions.terminate, quickActions.connect])],
]);

// TODO: Delete this once multiversion is in Prod ?
const getIsHPCJob = (isMultiVersionEnabled) =>
   isMultiVersionEnabled
      ? pathEq(jobTypeKeys.hpc, ['jobDefinition', 'jobType'])
      : propEq(jobTypeNames.hpc, 'jobTypeName');

const getIsSession = (isMultiVersionEnabled) =>
   isMultiVersionEnabled
      ? pathEq(jobTypeKeys.remote, ['jobDefinition', 'jobType'])
      : propEq(jobTypeNames.remote, 'jobTypeName');

export const createIsOneSessionInProcess = (isMultiVersionEnabled) => {
   const isSession = getIsSession(isMultiVersionEnabled);
   return any(both(isSession, isInProcess));
};

export const createIsOneJobPreparing = (isMultiVersionEnabled) => {
   const isHPCJob = getIsHPCJob(isMultiVersionEnabled);
   return any(both(isHPCJob, isPreparing));
};

// TODO: we may reactivate these lines once multiversion is in prod instead of previous ones?
// const isSession = pathEq(jobTypeKeys.remote, ['jobDefinition','jobType']);
// const isHPCJob = pathEq(jobTypeKeys.hpc, ['jobDefinition','jobType']);
// export const isOneSessionInProcess = any(both(isSession, isInProcess));
// export const isOneJobPreparing = any(both(isHPCJob, isPreparing));
