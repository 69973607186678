import hpcJobIcon from '/img/hpcJob.svg';
import remoteDesktopJobIcon from '/img/remoteDesktopJob.svg';

export const hpcJobTemplate = {
   key: 'hpc',
   id: 'hpcJob',
   imageSrc: hpcJobIcon,
   dataCy: 'newCloudHPCJob',
};

export const remoteDesktopTemplate = {
   key: 'remote',
   id: 'remoteDesktop',
   imageSrc: remoteDesktopJobIcon,
   dataCy: 'newRemoteDesktop',
};

export const projectTemplates = [hpcJobTemplate, remoteDesktopTemplate];
