import { dataStates, remoteSessionDefaults } from '@/utils/constants';

export const model = {
   dataState: dataStates.loading,
   error: null,
   form: {
      name: { value: '', error: null },
      idleTime: remoteSessionDefaults.idleTime,
      version: { value: '', error: null },
      submissionTemplate: { value: null, error: null },
   },
   details: {
      submissionTemplate: {
         id: '',
         name: '',
         description: '',
         hourlyCreditRate: 0,
      },
   },
};
