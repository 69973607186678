import { compose, find, isNotEmpty, mergeDeepLeft, prop, propEq, propOr } from 'ramda';

import { createApp } from '@/store/common/pal';

import { dataStates } from '@/utils/constants';

import { rawActions } from './actions';

export const initialFeatureFlags = {
   userFeatureFlags: [],
   dataState: dataStates.loading,
   isMultiVersionEnabled: false,
};

const getMultiversionFlag = compose(propOr(true, 'value'), find(propEq('multiversion', 'name')));
const hasFeatureFlag = compose(isNotEmpty, prop('userFeatureFlags'));

const nap = ({ proposedState }) => {
   if (hasFeatureFlag(proposedState)) {
      const isMultiVersionEnabled = getMultiversionFlag(proposedState.userFeatureFlags);
      return mergeDeepLeft({ isMultiVersionEnabled }, proposedState);
   }

   return proposedState;
};

export const featureFlagsStore = createApp(rawActions, initialFeatureFlags, nap);
