import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { store } from '@/store/settings/store';
import { useStream } from '@/store/useStream';

import { Access as TelemetryAccess } from '@/modules/settings/telemetry/Access';

import { dataStates, isAutomatedTest } from '@/utils/constants';

import { SwwcButton, SwwcCheckbox, SwwcLink, SwwcModalDialog } from '@/swwc';

import { csa } from './constant';

export function Access() {
   const client = useClient();
   const { t } = useTranslation();
   const {
      userEntitlementData: { id, dataState },
      granted,
      telemetry: { viewedTelemetry },
   } = useStream(store.state);
   const [checked, setChecked] = useState(false);

   function onValidate() {
      store.actions.updateEntitlement(client, id);
   }

   // Access has been granted, nothing to show
   if (granted || dataState === dataStates.loading || isAutomatedTest) {
      return null;
   }

   return (
      <div>
         <SwwcModalDialog id={csa.id} dialog-title={t(csa.title)} opened isModal type="input">
            <TelemetryAccess />
            <div className="m-4 flex">
               <SwwcCheckbox
                  className="mr-1 flex items-center"
                  label={t(csa.information)}
                  checked={checked}
                  onCheckChanged={() => setChecked(!checked)}
               />
               <SwwcLink
                  className="flex items-center"
                  url={csa.policyLink}
                  text={t(csa.name)}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
               />
            </div>
            <SwwcButton
               label="Ok"
               slot="actions"
               size="sm"
               className="my-1 pr-2 pl-4"
               disabled={!checked && viewedTelemetry}
               onClick={onValidate}
            />
         </SwwcModalDialog>
      </div>
   );
}
