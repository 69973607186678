// @ts-nocheck
import {
   __,
   compose,
   curry,
   defaultTo,
   either,
   endsWith,
   filter,
   find,
   head,
   invoker,
   last,
   mergeLeft,
   objOf,
   path,
   pathOr,
   prop,
   propEq,
   propOr,
   where,
} from 'ramda';

import { numberRegex } from './constants';

export const findById = compose(find, propEq(__, 'id'));
export const findByResourceId = compose(find, propEq(__, 'resourceId'));

export const findByType = compose(find, propEq(__, 'type'));

const getTaskNameByExt = compose(find, where, objOf('filename'), endsWith);

const combinator = curry((f, g, x, y) => g(f(x)(y)));

export const getFilename = combinator(getTaskNameByExt, prop('filename'));

export const getTask = compose(defaultTo({}), head, propOr([], 'tasks'));

export const getTaskId = compose(prop('id'), getTask);

export const getBalance = propOr('N/A', 'creditBalance');

const taskStatus = (key) => compose(prop(key), last, pathOr([], ['tasks']));

export const getStatus = either(taskStatus('unifiedStatus'), prop('status'));

export const getFileFromTarget = path(['target', 'files', 0]);

export const extractCores = (description) => {
   const matchNumber = description.match(numberRegex);
   return matchNumber ? matchNumber[0] : '0';
};
export const findFromKey = (key) => find(propEq(key, 'key'));
export const filterFromKey = (key) => filter(propEq(key, 'key'));

export const getReadableFileSize = (sizeInBytes) => {
   if (sizeInBytes <= 0) {
      return '0';
   }
   const siThreshold = 1000; // SI units are in 1000 not 1024 blocks
   const siUnits = ['B', 'kB', 'MB', 'GB'];
   const unitIndex = Math.min(siUnits.length - 1, Math.floor(Math.log(sizeInBytes) / Math.log(siThreshold)));
   const sizeInUnits = (sizeInBytes / Math.pow(siThreshold, unitIndex)).toFixed(unitIndex === 0 ? 0 : 2);
   return `${sizeInUnits} ${siUnits[unitIndex]}`;
};

export const getJsonWithStatus = (r) => r.json().then(mergeLeft({ httpStatus: r.status }));

const toFixed = invoker(1, 'toFixed');
export const getDisplayedCost = compose(toFixed(2), pathOr(0, ['debitReport', 'amount']));
export const getTemplateByMarketingVersion = compose(find, propEq(__, 'marketingVersion'));

export const getRDVersion = pathOr('', ['remote_desktop', 0, 'marketingVersion']);
