export const model = {
   name: '',
   simulationFile: null,
   macro: null,
   otherFiles: [],
   submissionTemplate: null,
   version: { selected: null, file: null },
   precision: { selected: null, file: null },
   type: 'star-ccmp',
   errors: {
      name: false,
      simulationFile: false,
      macro: false,
      otherFiles: false,
      submissionTemplate: false,
   },
   canSubmit: false,
};
