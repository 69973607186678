// @ts-nocheck
import { always, compose, endsWith, ifElse, isNil, isNotNil, prop, propOr, when } from 'ramda';

import { extractVersion } from './formatters';
import { readInFile } from './readFile';

const extractJSON = (content) => {
   const start = content.indexOf('{');
   const end = content.indexOf('}', start);

   const s = content
      .slice(start, end + 1)
      .replace(/'/g, '"') // replace ' with "
      .replace(/[^ -~]+/g, '') // remove control character like Binary: [][][]
      .replace(/[0-9]*L/g, (l) => l.replace(/L$/g, '')); // remove L from long numbers to avoid parsing error, e.g. 2293627720L

   return JSON.parse(s);
};

/*
 *  Get the ReleaseNumber - first try to get the PresentationVersion directly,
 *  if it is not there, or it has an old syntax, fall back to ReleaseNumber e.g. {'BuildArch': 'linux-x86_64-2.17',
 *  'BuildEnv': 'gnu11.2-r8', 'ClassName': 'StarVersion', 'MachineConfig': 'Serial', 'PresentationVersion': '2306',
 *  'ReleaseDate': 'Wed Feb 15 02:15:53 UTC 2023', 'ReleaseNumber': '18.03.053'}
 */
const getHeaderJson = (file) => (position) => readInFile(file.slice(position, position + 300)).then(extractJSON);

/*
 *  Get the StatePosition - this is showing us the position of the ReleaseNumber in the file
 *  e.g. {'Binary': '[][][]', 'ClassName': 'STAR', 'StatePosition': 2293627720L, 'Version': 3}
 */
const getStatePosition = (blob) =>
   readInFile(blob)
      .then(extractJSON)
      .then(prop('StatePosition'))
      .then(
         when(isNil, () => {
            throw new Error('missing position');
         }),
      );

const getPrecision = compose(ifElse(endsWith('-r8'), always('double'), always('mixed')), propOr('', 'BuildEnv'));

const getVersion = ({ PresentationVersion, ReleaseNumber }) => {
   if (isNotNil(PresentationVersion) && isFinite(Number(PresentationVersion))) {
      return parseFloat(PresentationVersion);
   }

   return isNotNil(ReleaseNumber) ? extractVersion(ReleaseNumber) : 0;
};

const getVersionAndPrecision = (config) => ({ precision: getPrecision(config), version: getVersion(config) });

export const getVersionAndPrecisionFromFile = (file) => {
   return getStatePosition(file.slice(0, 100))
      .then(getHeaderJson(file))
      .then(getVersionAndPrecision)
      .catch(always({ version: 0, precision: 'mixed' }));
};
