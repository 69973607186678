import { useTranslation } from 'react-i18next';

import { featureFlagsStore } from '@/store/featureFlags/state';
import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import { accessors, downCaret, oldAccessors, upCaret } from '@/utils/constants';

import { SwwcCheckbox } from '@/swwc';

const onSorting = (accessor, isMatching) => {
   if (isMatching) {
      store.actions.flipSortingDirection();
   } else {
      store.actions.setSorting(accessor);
   }
};

const TableHeader = (accessor, sorting) => {
   const { t } = useTranslation();
   const isMatching = sorting.accessor.label === accessor.label;

   return (
      <th
         key={crypto.randomUUID()}
         className="cursor-pointer"
         data-accessor={accessor.label}
         onClick={() => onSorting(accessor, isMatching)}
      >
         {t(`labels.${accessor.label}`)}
         {isMatching && (
            <span className="absolute pl-3">{sorting.direction === 'ascending' ? upCaret : downCaret}</span>
         )}
      </th>
   );
};

export const TableHeaders = ({ nSelectedJobs }) => {
   const { t } = useTranslation();
   const { sorting, totalDisplayedJobs } = useStream(store.state);

   const indeterminate = nSelectedJobs > 0 && nSelectedJobs !== totalDisplayedJobs;
   const checked = nSelectedJobs > 0 && nSelectedJobs === totalDisplayedJobs;

   const { isMultiVersionEnabled } = useStream(featureFlagsStore.state);
   const accessorList = isMultiVersionEnabled ? accessors : oldAccessors;

   return (
      <thead>
         <tr>
            <th>
               <div className="flex items-center justify-center">
                  <SwwcCheckbox
                     checked={checked}
                     indeterminate={indeterminate}
                     onClick={store.actions.clearSelection}
                  />
               </div>
            </th>
            {accessorList.map((jobAccessor) => TableHeader(jobAccessor, sorting))}
            <th data-accessor="quickActions">{t('labels.quickActions')}</th>
         </tr>
      </thead>
   );
};
