import { compose, flip, invoker, mergeLeft, prop } from 'ramda';

import { jobTypeNames } from '@/utils/constants';

const addToSet = flip(invoker(1, 'add'));
const addJobTypeToSet = (set) => compose(addToSet(set), prop('jobTypeName'));
export const nap = ({ proposedState }) => {
   if (proposedState.selection.length > 0) {
      const jobTypeSet = new Set();
      proposedState.selection.forEach(addJobTypeToSet(jobTypeSet));

      return mergeLeft({ type: getSelectionType(jobTypeSet) }, proposedState);
   }

   return proposedState;
};

function getSelectionType(set) {
   if (set.size === 1) {
      return set.values().next().value === jobTypeNames.hpc ? 'hpc' : 'remote';
   }

   return 'mixed';
}
