import {
   append,
   both,
   compose,
   filter,
   find,
   has,
   isEmpty,
   isNil,
   mergeDeepRight,
   path,
   pathEq,
   propEq,
   unless,
   when,
} from 'ramda';

import { featureFlagsStore } from '@/store/featureFlags/state';
import { jobTypesStore } from '@/store/jobtypes/store';
import { runningJobs, transformState } from '@/store/listing/sortAndFilter';

import { createIsOneJobPreparing, createIsOneSessionInProcess } from '@/modules/listing/utils';

import { getStatus } from '@/utils/accessors';
import { isNilOrEmpty } from '@/utils/comparators';
import { computeJobStatus, pollDelay, statusOrder } from '@/utils/constants';
import { multiVersionConsumptionRate, nonMultiVersionConsumptionRate } from '@/utils/formatters';
import { delay } from '@/utils/utils';

const whenWorkspaceUpdated = when(both(propEq('replace', 'op'), propEq('/workspace', 'path')));

const filterRunningJobs = filter(propEq(computeJobStatus.Running, 'status'));

const calcCost = (runningJobs) => {
   const { mappings, templates } = jobTypesStore.state();
   const { isMultiVersionEnabled } = featureFlagsStore.state();

   return isMultiVersionEnabled
      ? multiVersionConsumptionRate(mappings, templates, runningJobs)
      : nonMultiVersionConsumptionRate(runningJobs);
};

const addTotalCost = (runningJobsList) => ({
   jobs: runningJobsList,
   totalCost: calcCost(runningJobsList),
});

/*
   As we poll the workspace will also handle updates on the frontend exclusively,
   we need to compare jobs on polls to determine if we update the store or keep the values modified by the frontend
*/
const verifyDefaults = (current, proposed) => {
   // if current workspace has no jobs, overwrite with the proposed workspace
   if (isEmpty(current.workspace.jobs)) {
      return proposed;
   }
   const nextJobs = proposed.workspace.jobs.reduce((jobs, proposedJob) => {
      const currentJob = find(propEq(proposedJob.id, 'id'), current.workspace.jobs);

      if (isNil(currentJob)) {
         // if currentProposedJob is newly created
         return append(proposedJob, jobs);
      }

      // Compare current job vs proposed job task status to determine which should be kept
      const proposedStatusWeight = statusOrder[getStatus(proposedJob)] ?? 0;
      const currentStatusWeight = statusOrder[getStatus(currentJob)] ?? 0;

      const jobToKeep = proposedStatusWeight >= currentStatusWeight ? proposedJob : currentJob;

      return append(jobToKeep, jobs);
   }, []);

   return mergeDeepRight(proposed, { workspace: { jobs: nextJobs } });
};

const deriveRunningJobs = compose(runningJobs, addTotalCost, filterRunningJobs);

const safeDeriveRunningJobs = compose(unless(isNilOrEmpty, deriveRunningJobs), path(['value', 'jobs']));

// TODO revert this back when multiversion is in Prod and remove isMultiVersionEnabled parameter in the calls
//const safeDeriveRunningSession = compose(unless(isNilOrEmpty, isOneSessionInProcess), path(['workspace', 'jobs']));
//const safeDerivePreparingJobs = compose(unless(isNilOrEmpty, isOneJobPreparing), path(['workspace', 'jobs']));

const safeDeriveRunningSession = (isMultiVersionEnabled) => {
   const isOneSessionInProcess = createIsOneSessionInProcess(isMultiVersionEnabled);

   return compose(unless(isNilOrEmpty, isOneSessionInProcess), path(['workspace', 'jobs']));
};

const safeDerivePreparingJobs = (isMultiVersionEnabled) => {
   const isOneJobPreparing = createIsOneJobPreparing(isMultiVersionEnabled);

   return compose(unless(isNilOrEmpty, isOneJobPreparing), path(['workspace', 'jobs']));
};

const isPollRequired = both(pathEq('/workspace', ['proposal', 'path']), pathEq(true, ['context', 'poll']));
const getJobs = ({ context, actions }) => delay(pollDelay).then(() => actions.loadJobs(context.client, true));
const getComputeJobs = ({ context, actions }) =>
   delay(pollDelay).then(() => actions.loadComputeJobs(context.client, true));

/**
 * The nap is invoked after an action has run and updated the workspace; it is intended
 * for derived data nad follow on actions.
 * @param {*} param0
 */
export const nap = ({ proposal, actions, context, currentState, proposedState }) => {
   const { isMultiVersionEnabled } = featureFlagsStore.state();
   when(isPollRequired, isMultiVersionEnabled ? getJobs : getComputeJobs)({ proposal, context, actions });
   whenWorkspaceUpdated(safeDeriveRunningJobs, proposal);
   const warnUserHPCJob = safeDerivePreparingJobs(isMultiVersionEnabled)(proposedState);
   const runningSessions = safeDeriveRunningSession(isMultiVersionEnabled)(proposedState);
   const updatedProposedState = mergeDeepRight(proposedState, {
      warnUserRemoteSession: { runningSessions: Array.isArray(runningSessions) ? false : runningSessions },
      warnUserHPCJob: Array.isArray(warnUserHPCJob) ? false : warnUserHPCJob,
   });

   const nextState = whenWorkspaceUpdated(() => verifyDefaults(currentState, updatedProposedState), proposal);

   return mergeDeepRight(proposedState, transformState(has('workspace', nextState) ? nextState : updatedProposedState));
};
