import { EllipsisButton } from './EllipsisButton';
import { PageButton } from './PageButton';
import { getPages } from './utils';

export const Pages = ({ page, totalPages, onPageChange }) => {
   const pages = getPages(page, totalPages);
   const uid = crypto.randomUUID();

   return pages.map((n, i) =>
      n === '…' ? (
         <EllipsisButton key={`${uid}-${i}`} />
      ) : (
         <PageButton key={`${uid}-${i}`} page={n} isActive={n === page} onClick={() => onPageChange(n)} />
      ),
   );
};
