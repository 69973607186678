import { curry, mergeLeft, path, pick, when } from 'ramda';

import {
   ComputeJob,
   ComputeTaskFileRepositories,
   CreateComputeJob,
   CreateComputeTask,
   CreateJob,
   SubmitComputeJob,
   SubmitJob,
} from '@/gql';
import { fetchPolicies } from '@/gql/constant';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { hasGQLError } from '@/utils/comparators';
import { applications, errorTypes, jobTypeKeys } from '@/utils/constants';
import { GQLError } from '@/utils/customErrors';

import { pollWithCancel, throwWhenError } from './common/utils';

const getJobDetails = (client, jobId) =>
   client
      .query(ComputeJob, { jobId })
      .toPromise()
      .then(throwWhenError(errorTypes.jobGetError))
      .then(path(['data', 'node']));

const createTask = curry((client, { simulationFile, macro }, job) =>
   client
      .mutation(CreateComputeTask, {
         input: {
            computeJobId: job.id,
            name: simulationFile.name,
            application: applications.StarCCMp,
            cmdOptions: [
               '-power',
               '-graphics',
               'mesa_swr',
               '-rgraphics',
               'mesa_swr',
               '-mpi',
               'openmpi',
               '-batch',
               macro?.name ?? 'run',
               simulationFile.name,
            ],
            launch: true,
         },
      })
      .toPromise()
      .then(path(['data', 'createComputeTask', 'task']))
      .then(mergeLeft({ computeJobId: job.id, resourceId: job.resourceId }))
      .catch((err) => {
         throw new GQLError(errorTypes.jobSubmitError, err);
      }),
);

const createComputeJob = (client, { name, submissionTemplate }, jobTypeId) =>
   client
      .mutation(CreateComputeJob, {
         input: { name, submissionTemplateId: submissionTemplate.id, jobTypeId },
      })
      .toPromise()
      .then(
         when(hasGQLError, (res) => {
            const error = path(['error', 'graphQLErrors', '0', 'extensions', 'serviceError'], res);
            throw new Error('Creation failed', { cause: { type: error?.code, parameters: error?.parameters } });
         }),
      )
      .then(path(['data', 'createComputeJob', 'job']));

const createJob = (client, input) =>
   client
      .mutation(CreateJob, { input })
      .toPromise()
      .then(throwWhenError('createJob'))
      .then(path(['data', 'createJob', 'job']));

const createJobAndInput = (client, form, applicationTemplates, idempotencyToken) => {
   // Find the application template corresponding to the form
   const app = getTemplateByMarketingVersion(form.version.selected)(applicationTemplates);

   const application = {
      type: app.type,
      version: app.version,
      precision: app.precision[0],
      typeHint: app.typeHint,
   };

   // TODO move this to the action?
   const taskInput = {
      name: form.simulationFile.name,
      application: applications.StarCCMp,
      cmdOptions: [
         '-power',
         '-graphics',
         'mesa_swr',
         '-rgraphics',
         'mesa_swr',
         '-mpi',
         'openmpi',
         '-batch',
         form.macro?.name ?? 'run',
         form.simulationFile.name,
      ],
      launch: true,
   };

   return createJob(client, {
      name: form.name,
      jobDefinition: {
         jobType: jobTypeKeys.hpc,
         applications: [application],
         computeType: pick(['name', 'typeHint'], form.submissionTemplate),
      },
      tasks: taskInput,
      idempotencyToken,
   });
};

const submitComputeJob = (client, computeJobId) =>
   client
      .mutation(
         SubmitComputeJob,
         { input: { computeJobId } },
         {
            additionalTypenames: ['Workspace'],
         },
      )
      .toPromise()
      .then(
         when(hasGQLError, (res) => {
            const error = path(['error', 'graphQLErrors', '0', 'extensions', 'serviceError'], res);
            throw new Error('Submission failed', { cause: { type: error.code, parameters: error.parameters } });
         }),
      )
      .then(path(['data', 'submitComputeJob', 'job']));

const submitJob = (client, id) =>
   client
      .mutation(SubmitJob, { input: { id } })
      .toPromise()
      .then(
         when(hasGQLError, (res) => {
            const error = path(['error', 'graphQLErrors', '0', 'extensions', 'serviceError'], res);
            throw new Error('Submission failed', { cause: { type: error.code, parameters: error.parameters } });
         }),
      )
      .then(path(['data', 'submitJob', 'job']));

const computeTaskFileRepositories = (client, computeTaskId, validate) => {
   const fn = () =>
      client
         .query(
            ComputeTaskFileRepositories,
            { computeTaskId },
            {
               requestPolicy: fetchPolicies.networkOnly,
            },
         )
         .toPromise();
   return pollWithCancel({ fn, validate });
};

export default {
   computeTaskFileRepositories,
   submitJob,
   submitComputeJob,
   createComputeJob,
   createJobAndInput,
   createJob,
   createTask,
   getJobDetails,
};
