import { useTranslation } from 'react-i18next';

import { buildJobActionsClickHandler } from '@/modules/common/buildJobActionsClickHandler';
import { getActions } from '@/modules/listing/utils';

import { SwwcButton } from '@/swwc';

export const QuickActions = ({ job }) => {
   const onClick = buildJobActionsClickHandler(job);
   const { t } = useTranslation();

   return (
      <div className="flex items-center justify-center gap-2">
         {getActions(job).map(({ icon, id }) => (
            <SwwcButton
               key={`action-${id}`}
               variant="chromeless"
               icon={icon}
               size="xs"
               onClickEvent={() => onClick(id)}
               title={t(`commands.${id}`)}
               iconPosition="center"
               hiddenLabel
            />
         ))}
      </div>
   );
};
