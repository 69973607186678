import { setMessage } from './errors';

class GQLError extends Error {
   constructor(type, ...params) {
      super(...params);

      this.type = type;
      this.message = setMessage(params[0].networkError);
   }
}

export { GQLError };
