// @ts-nocheck
import {
   always,
   any,
   anyPass,
   compose,
   cond,
   includes,
   isEmpty,
   mergeDeepRight,
   path,
   prop,
   propSatisfies,
   split,
   T,
   trim,
   values,
} from 'ramda';

import { dataStates } from '@/utils/constants';
import { nameConstraints } from '@/utils/errors';

const isRDNameInvalid = anyPass(nameConstraints);
const getKey = compose(path(['2']), split('/'), prop('path'));
const checkProposedState = (form, proposal) => {
   const key = getKey(proposal);
   const value = path([key, 'value'])(form);

   if (key === 'name') {
      return mergeDeepRight(form, {
         [key]: { value, error: compose(isRDNameInvalid, trim)(value) },
      });
   }

   if (key === 'version') {
      return mergeDeepRight(form, { [key]: { value, error: isEmpty(value) } });
   }

   return mergeDeepRight(form, { [key]: { value } });
};
const hasError = any(prop('error'));
const hasEmptyValue = any(compose(isEmpty, prop('value')));
const handleDataState = compose(
   cond([
      [hasError, always(dataStates.error)],
      [hasEmptyValue, always(dataStates.loading)],
      [T, always(dataStates.ready)],
   ]),
   values,
);

const isUpdatingForm = propSatisfies(includes('/form'), 'path');

export const nap = ({ proposedState, proposal }) => {
   if (isUpdatingForm(proposal)) {
      const updatedState = checkProposedState(proposedState.form, proposal);
      return { dataState: handleDataState(updatedState), form: updatedState, details: proposedState.details };
   }

   return proposedState;
};
