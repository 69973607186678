import { isNil } from 'ramda';

import { jobTypeKeys, jobTypeNames } from '@/utils/constants';

/**
 * To create events, either use this for more complex ones
 *    let event = San.newEvent(<eventName>);
 *    event.addProperty(<propertyName>, <value>);
 *    event.log();
 *
 * Or this for simple ones:
 *    San.logEvent(<eventName>, <property>)
 */

const events = {
   submitRemote: 'SubmitRemoteDesktopSession',
   submitHPC: 'SubmitCloudHPCJob',
   openRemote: 'OpenRemoteDesktopSession',
   openWMC: 'OpenWebMonitorClient',
   terminateHPC: 'TerminateCloudHPCJob',
   terminateRemote: 'TerminateRemoteDesktopSession',
   deleteHPC: 'DeleteCloudHPCJob',
   deleteRemote: 'DeleteRemoteDesktopSession',
   openWalkMe: 'OpenWalkMe',
};

/* Logs the user submitting a new Cloud HPC Job after its successfull creation
   Information sent:
      - Seconds spent from the arrival on the form page until submission
      - Job ID
      - Submission template name selected
*/
export const trackSubmitNewHPCJob = (San, { template, seconds, jobId }) => {
   if (isNil(San)) {
      return;
   }

   const event = San.newEvent(events.submitHPC);
   event.addProperty('jobId', jobId);
   event.addProperty('clusterSize', template);
   event.addProperty('duration', seconds);
   event.log();
};

/* Logs the user submitting a new Remote Desktop Session after its successfull creation
   Information sent:
      - Seconds spent from the arrival on the form page until submission
      - Job ID
*/
export const trackSubmitNewRemoteSession = (San, { jobId, seconds }) => {
   if (isNil(San)) {
      return;
   }

   const event = San.newEvent(events.submitRemote);
   event.addProperty('jobId', jobId);
   event.addProperty('duration', seconds);
   event.log();
};

// Logs the user deleting a job/session
export const trackDeletion = (San, { jobTypeName, id }) => {
   if (isNil(San)) {
      return;
   }

   const eventType = jobTypeName === jobTypeNames.hpc ? events.deleteHPC : events.deleteRemote;
   const event = San.newEvent(eventType);
   event.addProperty('jobId', id);
   event.log();
};

// Logs the user terminating a cloud hpc job or remote desktop session
export const oldTrackTermination = (San, { jobTypeName, id }) => {
   if (isNil(San)) {
      return;
   }

   const eventType = jobTypeName === jobTypeNames.hpc ? events.terminateHPC : events.terminateRemote;
   const event = San.newEvent(eventType);
   event.addProperty('jobId', id);
   event.log();
};

// Logs the user terminating a cloud hpc job or remote desktop session
export const trackTermination = (San, { jobType, id }) => {
   if (isNil(San)) {
      return;
   }

   const eventType = jobType === jobTypeKeys.hpc ? events.terminateHPC : events.terminateRemote;
   const event = San.newEvent(eventType);
   event.addProperty('jobId', id);
   event.log();
};

// Logs the user connecting to the Web Monitor Client
export const trackOpenWMC = (San, jobId) => {
   if (isNil(San)) {
      return;
   }

   const event = San.newEvent(events.openWMC);
   event.addProperty('jobId', jobId);
   event.log();
};

// Logs the user connecting to a running remote desktop session
export const trackOpenRemote = (San, jobId) => {
   if (isNil(San)) {
      return;
   }

   const event = San.newEvent(events.openRemote);
   event.addProperty('jobId', jobId);
   event.log();
};
