import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { templates } from '@/store/projects/state';
import { useStream } from '@/store/useStream';

import { Callback } from '@/modules/landing/Callback';
import { Landing } from '@/modules/landing/Landing';
import { Listing } from '@/modules/listing/Listing';
import Settings from '@/modules/settings/Settings';

import { SwwcLoadingIndicator } from '@/swwc';

import { Layout } from './Layout';
import { Unauthorized } from './Unauthorized';
import { Session } from './remoteDesktop/Session';

/**
 * Lazily renders the routes for a given module (project template)
 * @param {*} param0
 * @returns
 */
function Module({ id }) {
   const Component = React.lazy(() => import(`./${id}/Router.jsx`));
   return (
      <React.Suspense fallback={<SwwcLoadingIndicator type="circular" style={{ width: '100%' }} />}>
         <Component />
      </React.Suspense>
   );
}

/**
 * Dynamically & lazily routes to different modules. This has a few advantages:
 *
 * - The list of modules can be defined in one place
 *    (here it is in store/projects/constants and could potentially come from an API).
 * - Each module is well partitioned and self-sufficient. Good for scalability (adding more modules).
 * - It allows for code splitting and faster load times (the code for a module is only loaded when needed)
 * Each 'project template' (templates.state) *must* have an `id`
 * which corresponds to a folder within the `modules` (i.e. relative to this file).
 * This folder *must* include a Router.jsx file which defines the routes for the project
 * @returns
 */
export function ModuleRouter() {
   const apps = useStream(templates.state);
   const routes = apps.map((app) => {
      return {
         // The wildcard * so child routes can be properly matched
         path: `${app.id}/*`,
         element: <Module id={app.id} index />,
      };
   });

   return useRoutes([
      // TODO - this breaks the modularity of the app and should be looked into
      // perhaps can be improved by using the `lazy` property of react-router to
      // render dynamic routes instead of our custom `Module` component
      // https://reactrouter.com/en/main/route/lazy
      {
         path: '/remoteDesktop/:sessionId/session',
         element: <Session />,
      },
      {
         // Protected routes
         element: <Layout />,
         children: [
            {
               index: true,
               element: <Listing />,
            },
            {
               path: 'settings',
               element: <Settings />,
            },
            {
               path: '*',
               element: <p>That page does not exist!</p>,
            },
            ...routes,
         ],
      },
      // TODO: Not clear if this comes from the marketing URL or in embedded mode
      {
         path: 'samauth/login',
         element: <Navigate to="/" replace />,
      },
      {
         path: 'callback',
         element: <Callback />,
      },
      {
         path: 'unauthorized',
         element: <Unauthorized />,
      },
      {
         path: 'landing',
         element: <Landing />,
      },
      // {
      //    path: 'jobmanager/newjob', // for redirecting to new job page in embedded mode
      //    element: <Navigate to="/hpcJob" replace />,
      // },
   ]);
}
