/* eslint-disable camelcase*/
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { createLogoutUrl } from '@/store/auth/auth';
import { authStore } from '@/store/auth/store';
import { useStream } from '@/store/useStream';

import { SwwcIcon, SwwcLink } from '@/swwc';

export const UserNavigationPanel = () => {
   const formRef = useRef(null);
   const {
      idTokenData: { email, family_name, given_name },
      accessToken,
   } = useStream(authStore.state);
   const { t } = useTranslation();

   const handleLogout = () => {
      formRef.current.submit();
      authStore.actions.clearAuthData(localStorage, false);
   };

   return (
      <div className="overflow-hidden">
         <div className="flex flex-col items-center gap-2 mb-4">
            <figure className="grid place-items-center bg-white rounded-full w-[40px] h-[40px] overflow-hidden">
               <SwwcIcon name="cmdUser24" size="lg" />
            </figure>
            <span>
               {given_name} {family_name}
            </span>
            <span>{email}</span>
         </div>
         <form method="POST" action={createLogoutUrl()} ref={formRef}>
            <input type="hidden" name="accessToken" value={accessToken} />
            <div className="flex gap-1 items-center">
               <SwwcIcon name="cmdSignout16" size="md" style={{ '--swwc-icon--color': '#FFFFFF' }} />
               <SwwcLink
                  text={t('commands.logOut')}
                  onClick={handleLogout}
                  style={{ '--swwc-link--color--normal--default--light': '#FFFFFF' }}
               />
            </div>
         </form>
      </div>
   );
};
