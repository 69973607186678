// @ts-nocheck

/*eslint-disable max-lines*/
import {
   AbortMultipartUploadCommand,
   CompleteMultipartUploadCommand,
   CreateMultipartUploadCommand,
   GetObjectCommand,
   S3Client,
   UploadPartCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { compose, equals, isNil, path, tap, when } from 'ramda';

import { IssueFrsFileRepositoryDownloadTickets, IssueFrsFileRepositoryUploadTickets } from '@/gql';

import { store as fileStore } from '@/store/files/store';
import { store } from '@/store/listing/state';

import { getTaskId } from '@/utils/accessors';
import { hasGQLError, isUploading } from '@/utils/comparators';
import { errorTypes } from '@/utils/constants';
import { GQLError } from '@/utils/customErrors';
import { sendEmbeddedMessage } from '@/utils/embeddedMessage';

import { computeChecksum, computeFilePartsData } from './fileUtils';

const sendTicketMessage = tap((tickets) => sendEmbeddedMessage('fileTransfer/newTickets', { tickets }));

export const getDownloadTicket = (client, repositoryId) =>
   client
      .mutation(IssueFrsFileRepositoryDownloadTickets, { input: { repositoryId } })
      .toPromise()
      .then(path(['data', 'issueFrsFileRepositoryDownloadTickets']))
      .then(sendTicketMessage);

const uploadFilesTicket = (client, repositoryId) =>
   client
      .mutation(IssueFrsFileRepositoryUploadTickets, { input: { repositoryId } })
      .toPromise()
      .then(
         when(hasGQLError, () => {
            throw new Error('Request for upload ticket failed');
         }),
      )
      .then(path(['data', 'issueFrsFileRepositoryUploadTickets']))
      .catch((err) => {
         throw new GQLError(errorTypes.uploadFilesError, err);
      });

const credentialsFromTicket = ({ accessKey, secretAccessKey, sessionToken, expiration }) => {
   return {
      accessKeyId: accessKey,
      secretAccessKey,
      sessionToken,
      expiration: new Date(expiration),
   };
};

/**
 * Returns a callback function that will call the graphQL query to set new credentials as needed
 * for the S3 client
 */
const getCredentials = (client, repositoryId) => () =>
   uploadFilesTicket(client, repositoryId).then(credentialsFromTicket);

function createS3Client(ticket, onGetCredentials) {
   const httpOptions = { socketTimeout: 0 };
   const retryDelayOptions = { maxRetries: 10 };
   return new S3Client({
      endpoint: ticket.serviceUrl,
      region: ticket.bucketRegion,
      credentials: onGetCredentials ?? credentialsFromTicket(ticket),
      maxAttempts: 10,
      retryDelayOptions,
      httpOptions,
      signatureVersion: 'v4',
   });
}

export const getClientAndSignedUrl = (filename) => (ticket) => {
   const s3 = createS3Client(ticket);
   const key = `${ticket.prefix}${filename}`;
   const command = new GetObjectCommand({ Bucket: ticket.bucket, Key: key });
   return getSignedUrl(s3, command);
};

const byTaskId = (id) => compose(equals(id), getTaskId);
const getJobByTaskId = (id) => store.state().workspace.jobs.find(byTaskId(id));
const isJobUploading = compose(isUploading, getJobByTaskId);

const progressCallback = (fileData, taskId, startTime, progressCounter) => {
   const total = fileData.fileSize;
   let loaded;
   if (isNil(progressCounter[fileData.fileId])) {
      loaded = 0;
   } else {
      loaded = progressCounter[fileData.fileId] * fileData.filePartSize;
      if (progressCounter[fileData.fileId] === fileData.fileNumParts) {
         loaded = fileData.fileSize;
      }
   }
   const elapsedSeconds = Math.round((Date.now() - startTime) / 1000);
   fileStore.actions.updateUploadProgress({ loaded, total, elapsedSeconds }, taskId, fileData.fileId);
   progressCounter[fileData.fileId]++;
};

const uploadPart = (
   file,
   fileData,
   startTime,
   taskId,
   uploadId,
   { s3, bucket, key },
   index,
   progressCounter,
   abortController,
) => {
   const start = index * fileData.filePartSize;
   const end = Math.min(start + fileData.filePartSize, fileData.fileSize);
   const partNumber = index + 1;
   const partData = file.slice(start, end);

   return partData
      .arrayBuffer()
      .then((buffer) => computeChecksum(buffer).then((checksum) => ({ checksum, buffer })))
      .then(({ checksum, buffer }) => {
         const uploadParams = {
            Bucket: bucket,
            Key: key,
            PartNumber: partNumber,
            UploadId: uploadId,
            Body: buffer,
            ChecksumAlgorithm: 'SHA256',
            ChecksumSHA256: checksum,
         };
         const uploadCommand = new UploadPartCommand(uploadParams);
         return s3
            .send(uploadCommand, { abortSignal: abortController.signal })
            .then((uploadResponse) => ({ checksum, uploadResponse }));
      })
      .then(({ checksum, uploadResponse }) => {
         // Track the progress of each part upload
         progressCallback(fileData, taskId, startTime, progressCounter);

         if (!isJobUploading(taskId)) {
            fileStore.actions.abortUpload(taskId, fileData.fileId);
            abortController.abort('Upload interrupted by user');
         }
         return Promise.resolve({ ETag: uploadResponse.ETag, PartNumber: partNumber, ChecksumSHA256: checksum });
      })
      .catch((e) => {
         throw new Error(`Upload interrupted for file ${fileData.fileName}.`, e);
      });
};

function runPromisesInChunks(promises, chunkSize, i = 0, uploadedParts = []) {
   // Check if all promises have been executed
   if (i >= promises.length) {
      return Promise.resolve(uploadedParts);
   }

   // Get the chunk of promises to execute concurrently
   const chunk = promises.slice(i, i + chunkSize);
   const chunkPromises = chunk.map((promise) => promise());
   // Wait for all promises in the chunk to either fulfill or reject
   return Promise.allSettled(chunkPromises)
      .then((chunkResults) => {
         // Check if any promise in the chunk was rejected
         const rejectedPromises = chunkResults.filter((result) => result.status === 'rejected');

         if (rejectedPromises.length > 0) {
            // Handle rejection of promises in the chunk
            const errors = rejectedPromises.map((result) => result.reason);
            throw new Error(errors[0]);
         }

         // Collect the results from the fulfilled promises in the chunk
         const fulfilledResults = chunkResults
            .filter((result) => result.status === 'fulfilled')
            .map((result) => result.value);

         uploadedParts.push(...fulfilledResults);

         // Recursively call the function for the next chunk
         return runPromisesInChunks(promises, chunkSize, i + chunkSize, uploadedParts);
      })
      .catch((error) => {
         throw error;
      });
}

function uploadFileToS3({ s3, bucket, keyPrefix }, taskId, progressCounter, abortController, file, fileId) {
   const fileData = computeFilePartsData(file, fileId);
   const key = `${keyPrefix}${fileData.fileName}`;
   const startTime = Date.now();
   let uploadId = null;

   const createUploadParams = { Bucket: bucket, Key: key, ChecksumAlgorithm: 'SHA256' };
   const createUploadCommand = new CreateMultipartUploadCommand(createUploadParams);
   return s3
      .send(createUploadCommand)
      .then((createUploadResponse) => {
         uploadId = createUploadResponse.UploadId;

         /**
          * Create an array of chunks of the file based on the maxPartSize (5Mb)
          * and the max number of files we want to treat in parallel (say 10)
          * Then loop around each chunk and upload each part of a chunk in parallel
          * before moving on to the next chunk
          */
         const uploadPromises = [];
         for (let index = 0; index < fileData.fileNumParts; index++) {
            uploadPromises.push(() =>
               uploadPart(
                  file,
                  fileData,
                  startTime,
                  taskId,
                  uploadId,
                  { s3, bucket, key },
                  index,
                  progressCounter,
                  abortController,
               ),
            );
         }

         const chunkSize = 10;
         return runPromisesInChunks(uploadPromises, chunkSize);
      })
      .then((uploadedParts) => {
         // Sort the uploaded parts array based on part number
         uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber);

         // Complete the multipart upload
         const completeParams = {
            Bucket: bucket,
            Key: key,
            UploadId: uploadId,
            MultipartUpload: { Parts: uploadedParts },
         };
         const completeCommand = new CompleteMultipartUploadCommand(completeParams);
         return s3.send(completeCommand);
      })
      .catch((e) => {
         abortUpload(uploadId, { s3, bucket, key });
         console.error(e);
      });
}

function abortUpload(uploadId, { s3, bucket, key }) {
   const abortParams = { Bucket: bucket, Key: key, UploadId: uploadId };
   const abortCommand = new AbortMultipartUploadCommand(abortParams);
   return s3.send(abortCommand);
}

const createConfig = (taskId) => {
   const infos = fileStore.state();
   const { sim, macro } = infos[taskId];

   const jsonData = {
      sim,
      macro,
   };

   return new File([JSON.stringify(jsonData)], '.config.json');
};

const uploadFileGuard = (uploadData, taskId, progressCounter, abortController) => (file, i) =>
   file.name === '.config.json' ? null : uploadFileToS3(uploadData, taskId, progressCounter, abortController, file, i);

export const uploadFiles = (client, repositoryId, files, taskId, form) => {
   const filesToUpload = form.otherFiles.length > 0 ? [...files, createConfig(taskId)] : files;
   const onGetCredentials = getCredentials(client, repositoryId);
   const abortController = new AbortController();
   const progressCounter = Array(filesToUpload.length).fill(1);

   return uploadFilesTicket(client, repositoryId)
      .then((ticket) => {
         const s3 = createS3Client(ticket, onGetCredentials);
         const bucket = ticket.bucket;
         const keyPrefix = ticket.prefix;

         return { s3, bucket, keyPrefix };
      })
      .then((uploadData) =>
         Promise.all(filesToUpload.map(uploadFileGuard(uploadData, taskId, progressCounter, abortController))),
      );
};
