// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { featureFlagsStore } from '@/store/featureFlags/state';
import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { QuickActions } from '@/modules/listing/components/QuickActions';
import { goToDetails } from '@/modules/listing/utils';

import { hasID, isStatusDeleting, isStatusTerminating } from '@/utils/comparators';
import { accessors, oldAccessors } from '@/utils/constants';

import { SwwcCheckbox } from '@/swwc';

import { OldTableCells } from './OldTableCells';
import { TableCells } from './TableCells';

export const TableBody = ({ jobs, selectedJobs }) => {
   const navigate = useNavigate();
   const { t } = useTranslation();
   const { isMultiVersionEnabled } = useStream(featureFlagsStore.state);

   const Table = isMultiVersionEnabled ? TableCells : OldTableCells;
   const accessorList = isMultiVersionEnabled ? accessors : oldAccessors;

   return (
      <tbody data-cy="jobTable">
         {jobs.map((job) => (
            <tr key={crypto.randomUUID()} onClick={() => goToDetails(navigate)(job)}>
               <td onClick={(e) => e.stopPropagation()}>
                  <div className="flex justify-center items-center">
                     <SwwcCheckbox
                        onCheckChanged={() => store.actions.selectJob(job)}
                        checked={hasID(job.id)(selectedJobs)}
                        id={job.id}
                        disabled={isStatusDeleting(job)}
                     />
                  </div>
               </td>
               {accessorList.map(Table(job, t))}
               <td onClick={(e) => e.stopPropagation()} data-accessor="quickActions">
                  {isStatusDeleting(job) || isStatusTerminating(job) ? (
                     <LoadingIndicator size="xsmall" />
                  ) : (
                     <QuickActions job={job} />
                  )}
               </td>
            </tr>
         ))}
      </tbody>
   );
};
