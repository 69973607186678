// @ts-nocheck
import {
   __,
   assoc,
   compose,
   curry,
   head,
   ifElse,
   isNil,
   isNotNil,
   lensPath,
   mergeDeepLeft,
   prop,
   propSatisfies,
   set,
   sortBy,
   unless,
} from 'ramda';

import { supportedVersion } from '@/utils/constants';
import { getVersionAndPrecisionFromFile } from '@/utils/versionCheck';

import { model } from './model';
import { areSyncConstraintsInvalid, validateField } from './utils';

/**
 * Sort from lowest to highest marketing version
 * @param {import('@/store/jobtypes/model').applicationList} param0
 */
const sortByMarketingVersion = sortBy(prop('marketingVersion'));

/**
 * Determines which version to select from the supported list based on the provided version and these factors:
 * - Select any exact match
 * - If the version is a B release and this is not supported but A release is: select A release
 * - Otherwise, select the closest higher version available
 * @param {import('@/store/jobtypes/model').applicationList} sortedApplications
 *    Application list sorted by marketing version
 * @param {number} version The version to compare against
 * @returns {number} selected version
 */
export const selectVersion = (sortedApplications, version) => {
   for (const { marketingVersion } of sortedApplications) {
      if (marketingVersion === version) {
         return marketingVersion;
      }

      if (!Number.isInteger(version) && parseInt(version) === marketingVersion) {
         return marketingVersion;
      }

      if (marketingVersion > version) {
         return marketingVersion;
      }
   }

   return null;
};

const decideVersion =
   (applications, currentSelection) =>
   ({ version, precision }) => {
      const sortedApplications = sortByMarketingVersion(applications);
      if (version > sortedApplications[sortedApplications.length - 1].marketingVersion) {
         throw new Error('invalidVersion', {
            cause: {
               version,
               latestSupported: sortedApplications[sortedApplications.length - 1].marketingVersion,
               key: 'invalidVersion',
            },
         });
      }
      // We must always choose the 'closest newer' version.
      const selected = selectVersion(sortedApplications, version);
      const newSelected = isNotNil(currentSelection) ? Math.max(currentSelection, selected) : selected;
      return {
         version: { selected: newSelected, file: version },
         precision: { selected: 'mixed', file: precision },
         errors: { simulationFile: false },
      };
   };

// TODO: only used for non-multiversion workflows, to be removed
const validateFile = ({ version, precision }) => {
   if (parseInt(version) > supportedVersion) {
      throw new Error('invalidVersion', { cause: { version, key: 'invalidVersion' } });
   }
   return {
      version: { selected: supportedVersion, file: version },
      precision: {
         selected: precision,
         file: precision,
      },
      errors: { simulationFile: false },
   };
};

/**
 * Given that this store can contain files and that the json operation
 * we generally use to present use the JSON.stringify method to create new references of
 * the store, we cannot use this method to present updates.
 * Instead, use callbacks with custom merge functions.
 *
 * It is ok though for initial settings / clears given that no files should be stored before/after
 */
export const actions = (present) => ({
   setInitialTemplate: unless(
      propSatisfies(isNil, 'cloud_hpc'),
      compose(present, assoc('value', __, { op: 'replace', path: '/submissionTemplate' }), head, prop('cloud_hpc')),
   ),

   changeFormValue: curry((key, value) => {
      present(mergeDeepLeft({ [key]: value, errors: { [key]: validateField({ key, value }) } }));
   }),

   setVersion: (version) => {
      present(set(lensPath(['version', 'selected']), parseFloat(version)));
   },

   setPrecision: (precision) => {
      present(set(lensPath(['precision', 'selected']), precision));
   },

   changeSimulationFile: (applications, currentSelection) => (value) => {
      return new Promise((resolve, reject) => {
         if (areSyncConstraintsInvalid(value)) {
            if (isNil(value)) {
               present({ version: { file: null } });
            }
            reject('syncConstraints');
         }

         resolve(value);
      })
         .then(getVersionAndPrecisionFromFile)
         .then(ifElse(() => isNil(applications), validateFile, decideVersion(applications, currentSelection)))
         .then(present)
         .catch((err) => {
            present({ errors: { simulationFile: err?.cause ?? true } });
         })
         .finally(() => {
            present({ simulationFile: value });
         });
   },

   clearForm: () => {
      // present the model directly since a replace operation with nested arrays are not updated
      present(model);
   },
});
